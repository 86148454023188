.ticket-listing,
.landing-page {
  .ticket-header-image {
    background-image: url('./assets/images/header.gif');
    background-position: center;
    background-size: contain;
    margin: 0;
    padding: 0;
    height: calc(100vw / 3);
    // @include media-breakpoint-up(md) {
    //   height: 500px;
    // }
  }

  .list-page {
    padding-top: 8vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  #ticket-listing {
    .card {
      border-bottom: 1px solid white;
      text-decoration: none !important;
      background-color: transparent;
    }
    .nigiri {
      font-size: 2rem;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 1rem;

      border: none;
      // border-bottom: 0.125rem $basetext solid;
    }

    .nigiri-content {
      transition: 0.2s;

      margin: 0;
      padding: 0;
      grid-auto-columns: 1fr;
      grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
      // grid-template-columns: 0.5fr 1fr 0.5fr;

      column-gap: 16px;

      padding-top: 3vw;
      padding-right: 0px;
      padding-bottom: 1vw;
      padding-left: 0px;

      .nigiri-date {
        display: flex;
        font-size: 48px;
        font-weight: 600;
        align-items: start;
        justify-content: end;
        @include media-breakpoint-down(md) {
          flex-direction: column;
          font-size: 40px !important;
        }
      }

      .city {
        display: flex;
        font-size: 48px;
        font-weight: 600;
        align-items: end;
        justify-content: start;
        transition: 0.2s;
        @include media-breakpoint-down(md) {
          font-size: 40px !important;
          padding-bottom: 0.5rem;
        }
      }

      .location-name {
        display: flex;
        font-weight: 100;
        // font-size: 40px;
        align-items: end;
        letter-spacing: 1px;
        justify-content: start;
        margin-bottom: -10px;
        @include media-breakpoint-down(md) {
          font-size: 16px !important;
          padding-bottom: 0.5rem;
          margin-bottom: 0;
        }
      }

      .nigiri-body {
        display: none;
      }

      .nigiri-cta {
        grid-column: 4;
        grid-row: auto;
        justify-content: end;
        align-items: end;
        .btn-block {
          width: inherit;
        }
        div {
          display: inherit;
        }

        .badge {
          position: relative;
        }
      }

      .badge {
        z-index: 2;
        top: 0;
        right: 0;
        font-size: 1.1vw;
        @media (max-width: 770px) {
          font-size: 1.7vw;
        }
      }

      .arrow {
        display: none;
        width: 1.7vw;
        height: auto;
        transform: rotate(-45deg);
        margin-left: 0.8vw;
      }
    }

    // .nigiri-content:hover {
    //   background-color: base$basetext;
    //   padding-top: 4vw;
    //   padding-left: 1vw;
    //   padding-right: 1vw;

    //   transition: 0.2s;

    //   .date {
    //   }

    //   .city {
    //     font-size: 2vw !important;
    //   }

    //   .location-name {
    //   }

    //   .nigiri-cta {
    //     .btn {
    //       background-color: base$basetext;
    //       transition: 0.2s;
    //     }

    //     .arrow {
    //       display: block;
    //     }
    //   }
    // }
  }

  @media (max-width: 480px) {
    #ticket-listing .nigiri-content {
      grid-auto-columns: 1fr;
      grid-template-rows: auto auto;
      grid-row-gap: 3vw;
      grid-template-columns: 1fr 1fr !important;
      grid-column-gap: 3vw;

      padding-top: 3vw !important;
      margin-top: 4vw !important;

      .nigiri-date {
        align-self: end;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        justify-self: end;

        font-size: 4vw;
      }

      .city {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        font-size: 7vw;
      }

      .location-name {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;

        font-size: 4vw;
      }

      .nigiri-cta {
        justify-self: end;
        grid-column-start: 2 !important;
        grid-column-end: 3 !important;
        grid-row-start: 2;
        grid-row-end: 3;

        .btn {
          font-size: 4vw !important;
        }
      }

      .arrow {
        width: 5vw;
      }

      .badge {
        font-size: 3vw !important;
        top: -1px !important;
      }
    }

    #ticket-listing .nigiri-content:hover {
      .city {
        font-size: 7vw !important;
      }
    }
  }
}

.sold-out-status_rescheduled_active {
  color: $teal;
}

.sold-out-status_sold_out {
  color: $danger;
}
